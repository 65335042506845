import React from "react";
import { useRouter } from "next/router";
import { IResponsePlans } from "@/services/endpoints/plans";
import { Container, ContainerBody, ContainerTitle } from "./styles";
import { Api } from "@/services/configs/axios";
import { parseToSnakeCase } from "@/utils/parseToSnakCase";
import { dictMedias } from "@/utils/SvgDictionaries/svg";

interface Iprops {
  plano: IResponsePlans;
}

export const Card = React.memo(({ plano }: Iprops) => {
  const router = useRouter();

  const handleNavigate = React.useCallback((id: number) => {
    router.push(`/plano/${id}`);
  }, [router]);

  const fetchIcon = async (iconPath: any) => {
    try {
      const response = await Api.get(`final/download/${iconPath}`);
      return response.data;
    } catch (error) {
      return null;
    }
  };

  const rehydratePlans = async () => {
    const iconUrl = await fetchIcon(plano?.arquivosPlano?.caminhoIcone);
    setMedias(iconUrl)
  }

  const [medias, setMedias] = React.useState<any>("");
  React.useEffect(() => {
    if (dictMedias[parseToSnakeCase(plano?.titulo)]?.icon != undefined) {
      setMedias(dictMedias[parseToSnakeCase(plano?.titulo)]?.icon);
    } else {
      rehydratePlans()
    }
  }, [plano?.titulo]);

  return (
    <Container onClick={() => handleNavigate(plano?.id)}>
      <ContainerTitle status={plano?.status}>
        <h1 className="resizeable-accessibility">{plano?.titulo}</h1>
      </ContainerTitle>
      <ContainerBody>
        {medias ? (
          <>
            {typeof medias === 'string' ? (
              <div dangerouslySetInnerHTML={{ __html: medias }} />
            ) : (
              medias
            )}
          </>
        ) : (
          plano?.svg
        )}

        <p style={{ height: '190px', maxHeight: '200px', overflow: 'hidden' }} className="resizeable-accessibility">{plano?.descricao}</p>
      </ContainerBody>
    </Container>
  );
});
